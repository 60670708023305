import Button from 'aac-components/components/Button';
import Image from 'next/image';
import {BREAKPOINT} from '../../lib/styles';
import {useContext} from 'react';
import AppContext from '../AppContext';
import styled from 'styled-components';
import {MAX_WIDTH_PX} from 'aac-components/utils/styles';
import FacilityCardSlider from '../FacilityCardSlider';
import ComparisonChart from 'aac-components/components/ComparisonChart';

const MapSection = () => {
    const {isMobile} = useContext(AppContext);
    return (
        <section className="section-map">
            <div className="section-map__inner">
                <div className="section-map__content">
                    <div className="section-map__content--left">
                        <div className="section-map__pre-title">
                            EXPERT ADDICTION TREATMENT
                        </div>
                        <h2 className="section-map__title">15+ Years of Trusted Care</h2>
                        <div className="section-map__copy">
                            At AAC, we treat drug and alcohol addiction along with mental
                            and behavioral health issues at multiple locations nationwide.
                        </div>
                        <div className="section-map__button-container">
                            <div className="section-map__button">
                                <Button
                                    href="/treatment-centers"
                                    theme="primary"
                                    style={{width: '100%', fontWeight: 'normal'}}>
                                    View Our Locations
                                </Button>
                            </div>
                            <div className="section-map__button">
                                <Button
                                    href="/treatment-centers/outpatient-rehab"
                                    theme="invert"
                                    style={{width: '100%', fontWeight: 'normal'}}>
                                    View Outpatient Locations
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className="section-map__content--right">
                        <Image
                            src="/static/homepage-assets/people-in-meeting.png"
                            fill
                            style={{objectFit: 'cover'}}
                            alt={'American Addiction Centers Photo'}
                            sizes="100vw"
                            priority
                        />
                    </div>
                </div>
                <div className="section-map__comparison-chart-treatment">
                    <div className="section-map__comparison-pre-title">
                        COMPARE TREATMENT
                    </div>
                    <h2 className="section-map__comparison-title">
                        Compare Our Nationwide Addiction Facilities
                    </h2>
                    <ComparisonChart isTreatmentChart={true} />
                </div>
                <div className="section-map__facilities">
                    <FacilityCardSliderStyles>
                        <FacilityCardSlider
                            showViewAllButton={false}
                            showFilter={false}
                        />
                    </FacilityCardSliderStyles>
                </div>
            </div>
            <style jsx>{`
                .section-map {
                    padding: 40px 15px;
                    text-align: left;
                }
                @media screen and (min-width: ${BREAKPOINT}) {
                    .section-map {
                        padding: 40px 0;
                    }
                }
                .section-map__inner {
                    max-width: ${MAX_WIDTH_PX};
                    margin: 0 auto;
                }
                .section-map__logo {
                    width: 250px;
                    height: 40px;
                    margin: 0 auto 24px auto;
                    position: relative;
                }
                @media screen and (min-width: ${BREAKPOINT}) {
                    .section-map__logo {
                        width: 250px;
                        height: 40px;
                        margin: 0 0 32px 0;
                        position: relative;
                    }
                }
                .section-map__comparison-title {
                    text-align: center;
                    margin: 14px 0 24px;
                }
                .section-map__comparison-pre-title {
                    text-align: center;
                    color: var(--secondary-300);
                    font-size: 12px;
                    line-height: 16px;
                    font-weight: 700;
                }
                .section-map__pre-title {
                    color: var(--secondary-300);
                    font-size: 12px;
                    line-height: 16px;
                    font-weight: 700;
                }
                .section-map__title {
                    margin: 14px 0 24px;
                }
                .section-map__copy {
                    margin-bottom: 24px;
                    line-height: 24px;
                }
                .section-map__button {
                    max-width: 100%;
                    margin-bottom: 16px;
                }
                @media screen and (min-width: ${BREAKPOINT}) {
                    .section-map__button {
                        max-width: 100%;
                    }
                }
                .section-map__content {
                    display: grid;
                    grid-template-columns: 1fr;
                    margin-bottom: 40px;
                }
                @media screen and (min-width: ${BREAKPOINT}) {
                    .section-map__content {
                        grid-template-columns: 1fr 1fr;
                        grid-gap: 80px;
                        align-items: center;
                    }
                }
                .section-map__content--right {
                    border-radius: 8px;
                    overflow: hidden;
                    position: relative;
                    height: 395px;
                }
                @media screen and (min-width: ${BREAKPOINT}) {
                    .section-map__content--right {
                        width: 570px;
                    }
                }
                .section-map__content--image {
                    position: relative;
                    min-width: 100%;
                    height: 200px;
                    margin-bottom: 60px;
                }
                @media screen and (min-width: ${BREAKPOINT}) {
                    .section-map__content--image {
                        height: 350px;
                        margin-bottom: 16px;
                    }
                }
                .section-map__button-container {
                    margin-bottom: 25px;
                }
                @media screen and (min-width: ${BREAKPOINT}) {
                    .section-map__button-container {
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        gap: 20px;
                        margin-bottom: 0;
                    }
                }
                @media screen and (min-width: ${BREAKPOINT}) {
                    .section-map__comparison-chart-treatment {
                        height: 670px;
                    }
                }
            `}</style>
        </section>
    );
};
export default MapSection;

const FacilityCardSliderStyles = styled.div`
    /* Track */
    .facility-card-slider::-webkit-scrollbar-track {
        background: var(--gray-200);

        border-radius: 2em;
    }

    /* Handle */
    .facility-card-slider::-webkit-scrollbar-thumb {
        background: var(--interactive-200);
        border-radius: 2em;
    }
`;
